.site-text-search-input {
	text-overflow: ellipsis;
}

.site-text-search-results-area {
	.col-mw-330 {
		min-width: 330px;
	}

	.col-mw-200 {
		min-width: 200px;
	}
}
